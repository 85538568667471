import { FC } from 'react';
import { FCWithChildren } from '../../../types/FCWithChildren';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import { Option } from '../../Option';
import IconRender from '../icon/IconRender';
import { Item } from './DropdownDefaultComponents';
import DropdownSelect from './DropdownSelect';
import { useTranslation } from 'react-i18next';

const IconPickerListWrapper: FCWithChildren = ({ children }) => {
  return <div className="flex flex-wrap items-center justify-center">{children}</div>;
};

const IconPickerListRenderer: FC<Item> = (props) => {
  return (
    <div>
      <IconRender type={props.id} className="h-7 w-7" />
    </div>
  );
};

type IconPickerProps = {
  options: Option<string, string | number>[];
  value?: Option<string, string | number> | null;
  onChange: (data: Option<string, string | number>) => void;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
};

const IconPicker: FC<IconPickerProps> = (props) => {
  const { options, onChange, disabled, value, placeholder, label } = props;
  const { t } = useTranslation('common');

  return (
    <DropdownSelect
      {...dataAttributeProps(props)}
      disabled={disabled}
      value={value}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      label={label}
      aria-label={label || t('aria-label.icon-select')}
      dropdownListWrapper={IconPickerListWrapper}
      customListRenderer={IconPickerListRenderer}
    />
  );
};

export default IconPicker;
